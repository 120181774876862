import {
  Delete,
  HighlightOff,
  Mic,
  Pause,
  PlayArrow,
  Refresh,
  Stop,
} from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { convertBlobToBase64 } from "./utils";
import { fetchSTTWhisper } from "../../APIS/clientAPIS";
import { useSnackbar } from "notistack";

const AudioVisualizer = ({ transcription, state, scrollOnChange }) => {
  const audioContextRef = useRef(null); // Web Audio API context
  const analyserRef = useRef(null); // Analyser node
  const canvasRef = useRef(null); // Canvas for visualization
  const mediaRecorderRef = useRef(null); // MediaRecorder for recording
  const audioChunksRef = useRef([]); // Audio data chunks
  const sourceRef = useRef(null); // Source node for microphone input
  const animationIdRef = useRef(null); // Reference to animation frame
  const timerIntervalRef = useRef(null); // Reference for the timer interval

  const [isRecording, setIsRecording] = useState(false); // Recording state
  const [isPaused, setIsPaused] = useState(false); // Pause state
  const [hasError, setHasError] = useState(null); // Error state
  const [timer, setTimer] = useState(0); // Timer in seconds
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [transcribedStatus, setTranscribedStatus] = useState([]); // To track transcription status
  const { enqueueSnackbar } = useSnackbar();
  const fileRef = useRef(null);

  useEffect(() => {
    const initAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 256; // FFT size for frequency analysis

        // Create source from the microphone input
        sourceRef.current =
          audioContextRef.current.createMediaStreamSource(stream);
        sourceRef.current.connect(analyserRef.current);

        // Initialize MediaRecorder
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: isSafari() ? "audio/mp4" : "audio/webm;codecs=opus",
        });
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data); // Store audio data chunks
        };
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: isSafari() ? "audio/mp4" : "audio/webm;codecs=opus",
          });

          setRecordings((prev) => [...prev, audioBlob]); // Set the recorded audio blob
          audioChunksRef.current = []; // Reset chunks for the next recording
        };

        // Start visualization
        // visualize();
      } catch (error) {
        setHasError(
          "Error accessing microphone. Please ensure microphone permissions are granted."
        );
        console.error("Error initializing audio:", error);
      }
    };

    initAudio();

    // Cleanup audio context on component unmount
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      cancelAnimationFrame(animationIdRef.current); // Stop any animations
      clearInterval(timerIntervalRef.current); // Stop the timer
    };
  }, []);

  const visualize = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const canvasCtx = canvas.getContext("2d");
    const analyser = analyserRef.current;
    if (!analyser) return;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      animationIdRef.current = requestAnimationFrame(draw);

      analyser.getByteFrequencyData(dataArray);

      // Clear the canvas
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      // Set bar width and spacing
      const barWidth = 2; // Thin bars
      const totalBarWidth = barWidth * bufferLength; // Total width of all bars
      const offsetX = (canvas.width - totalBarWidth) / 2; // Center the bars horizontally

      const centerY = canvas.height / 2; // The center line for the bars

      let x = offsetX; // Start drawing from the offset to center the bars

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataArray[i] / 2; // Scale the bar height

        // Set the color of the bars
        canvasCtx.fillStyle = `#D92D20`;

        // Draw the bar above and below the center line
        canvasCtx.fillRect(x, centerY - barHeight / 2, barWidth, barHeight);

        // Move to the next bar position
        x += barWidth + 1; // Add 1px space between bars
      }
    };

    draw();
  };

  const startRecording = () => {
    if (mediaRecorderRef.current && !isRecording) {
      mediaRecorderRef.current.start();
      setIsRecording(true);
      setIsPaused(false);
      setTimer(0); // Reset timer
      startTimer(); // Start the timer
      visualize();
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false);
      const canvas = canvasRef.current;
      if (canvas) {
        const canvasCtx = canvas.getContext("2d");
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas to remove visualizer
      }
      cancelAnimationFrame(animationIdRef.current); // Stop visualization when recording stops
      clearInterval(timerIntervalRef.current); // Stop the timer
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && isRecording && !isPaused) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      cancelAnimationFrame(animationIdRef.current); // Pause the visualization
      clearInterval(timerIntervalRef.current); // Pause the timer
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current && isRecording && isPaused) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimer(); // Resume the timer
      visualize(); // Resume the visualization
    }
  };

  const startTimer = () => {
    timerIntervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000); // Increment timer every second
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    if (minutes === 60) {
      stopRecording();
    }
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const removeRecording = (index) => {
    const updatedRecordings = [...recordings];
    const updatedTranscribedStatus = [...transcribedStatus];

    updatedRecordings.splice(index, 1); // Remove the recording at the specified index
    updatedTranscribedStatus.splice(index, 1); // Remove the transcription status at the same index

    setRecordings(updatedRecordings); // Update the recordings state
    setTranscribedStatus(updatedTranscribedStatus); // Update the transcription status state
  };

  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const sendAudioToApi = async (blob, index) => {
    setLoading(true);
    try {
      const base64String = await convertBlobToBase64(blob);
      const formattedBase64 = isSafari()
        ? String(base64String).split("data:audio/mp4;base64,")[1]
        : String(base64String).split("data:audio/webm;codecs=opus;base64,")[1];
      if (formattedBase64) {
        const whisperTranscription = await fetchSTTWhisper(
          JSON.stringify({ base64_audio: formattedBase64 })
        );
        transcription(whisperTranscription);

        // Update transcription status for this recording
        setTranscribedStatus((prevStatus) => {
          const newStatus = [...prevStatus];
          newStatus[index] = true; // Mark this recording as transcribed
          return newStatus;
        });

        setLoading(false);
        enqueueSnackbar("File transcribed successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  // Transcribe all recordings sequentially
  const transcribeAllRecordings = async () => {
    setLoading(true);
    let outputText = ""; // To accumulate transcription results

    try {
      const updatedTranscribedStatus = [...transcribedStatus]; // Create a copy of current transcribed statuses
      // Skip if already transcribed

      // Process each recording sequentially
      for (let index = 0; index < recordings.length; index++) {
        if (updatedTranscribedStatus[index] === true) {
          continue; // Skip to the next recording
        }
        const recording = recordings[index];
        const base64String = await convertBlobToBase64(recording);
        const formattedBase64 = isSafari()
          ? String(base64String).split("data:audio/mp4;base64,")[1]
          : String(base64String).split(
              "data:audio/webm;codecs=opus;base64,"
            )[1];

        if (formattedBase64) {
          try {
            const whisperTranscription = await fetchSTTWhisper(
              JSON.stringify({ base64_audio: formattedBase64 })
            );

            // Accumulate the transcription
            outputText += " " + whisperTranscription;

            // Mark this recording as successfully transcribed
            updatedTranscribedStatus[index] = true; // Update transcription status to true
          } catch (error) {
            console.error(
              `Error transcribing recording at index ${index}:`,
              error
            );
            updatedTranscribedStatus[index] = false; // Mark as failed transcription
          }
        }
      }

      // Update the transcribed status state after processing all recordings
      setTranscribedStatus(updatedTranscribedStatus);
      enqueueSnackbar("All files transcribed successfully", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error transcribing recordings:", error);
    } finally {
      setLoading(false); // Ensure loading is stopped even if there is an error
      transcription(outputText.trim()); // Pass the accumulated transcriptions to the parent component
    }
  };

  const scrollChatToBottom = () => {
    const chatElement = fileRef.current;
    if (chatElement) {
      // Use setTimeout to allow DOM updates to complete before scrolling
      setTimeout(() => {
        chatElement.scrollTop = chatElement.scrollHeight;
      }, 0);
    }
  };

  useEffect(() => {
    scrollChatToBottom();
    scrollOnChange();
  }, [recordings]);
  return (
    <div>
      {" "}
      {state === "editor" && (
        <div className="d-flex flex-column  align-items-center">
          {" "}
          <div
            style={{
              background: "white",
              width: "100%",
              boxShadow: "0px 20px 50px 0px var(--primary-accent-color)",
            }}
            className=" row row-cols-3 mb-4 w-100 rounded-bottom border-top py-2"
          >
            {hasError && <p style={{ color: "red" }}>{hasError}</p>}

            <div className="d-flex justify-content-center align-items-center">
              <canvas
                ref={canvasRef}
                style={{
                  height: "30px",
                  width: "100%",
                }}
              ></canvas>
            </div>

            <div className="d-flex justify-content-center gap-2 align-items-center">
              {!isRecording && (
                <IconButton
                  sx={{
                    backgroundColor: "var(--primary-color)",
                    textTransform: "capitalize",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "var(--primary-color-darken)",
                    },
                  }}
                  onClick={startRecording}
                >
                  <Mic />
                </IconButton>
              )}

              {isRecording && (
                <Button
                  className=" rounded-3 px-2"
                  sx={{
                    backgroundColor: "red",
                    textTransform: "capitalize",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "red",
                    },
                  }}
                  onClick={stopRecording}
                >
                  <Stop /> Stop
                </Button>
              )}
              {isRecording && !isPaused && (
                <Button
                  className=" rounded-3 px-2"
                  sx={{
                    backgroundColor: "#EAECF0",
                    textTransform: "capitalize",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "#EAECF0",
                    },
                  }}
                  onClick={pauseRecording}
                >
                  <Pause />
                  Pause
                </Button>
              )}
              {isRecording && isPaused && (
                <Button
                  className=" rounded-3 px-2 "
                  sx={{
                    backgroundColor: "#EAECF0",
                    textTransform: "capitalize",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "#EAECF0",
                    },
                  }}
                  onClick={resumeRecording}
                >
                  <PlayArrow /> Resume
                </Button>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              {isRecording && <>{formatTime(timer)}/60:00</>}{" "}
              {/* {audioBlob && (
            <Button
              sx={{
                backgroundColor: "white",
                textTransform: "capitalize",
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
              className=" rounded-3"
              startIcon={<HighlightOff />}
              onClick={clearRecording}
            >
              Cancel
            </Button>
          )} */}
            </div>
          </div>
          {recordings?.length > 0 && (
            <div
              style={{
                background: "#F9FAFB",
              }}
              className="w-100 position-relative border mb-3 rounded-3 p-2 p-md-4 "
            >
              <div className="d-flex justify-content-between flex-wrap gap-3 align-items-center">
                <h6 className="m-0">Recorded Audios</h6>
                <div className="d-flex justify-content-between align-items-center gap-3">
                  <Button
                    className=" rounded-3"
                    sx={{
                      backgroundColor: "#809b58",
                      textTransform: "capitalize",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#54653b",
                      },
                    }}
                    onClick={transcribeAllRecordings}
                  >
                    Transcribe All
                  </Button>

                  <Button
                    variant="text"
                    sx={{
                      borderRadius: "20px", // Rounded but not too much
                      bgcolor: "#FFFFFF",
                      color: "#414141",
                      fontSize: "14px", // 14px as in the default theme
                      textTransform: "capitalize",
                      "&:hover": {
                        background: "#FFFFFF",
                      },
                    }}
                    className="d-flex border justify-content-center align-items-center gap-2 rounded-3 px-3 "
                    onClick={() => {
                      setRecordings([]);
                      setTranscribedStatus([]);
                    }}
                  >
                    Remove All
                  </Button>
                </div>
              </div>
              <div
                ref={fileRef}
                style={{ maxHeight: "350px", overflowY: "scroll" }}
                className="mt-3 border-top pb-3"
              >
                {recordings.map((recording, index) => (
                  <div
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EAECF0",
                    }}
                    className="d-flex mt-3 flex-wrap gap-3  rounded-3 p-2 justify-content-between align-items-center"
                    key={index}
                  >
                    <audio
                      style={{
                        borderRadius: "10px",
                      }}
                      controls
                    >
                      <source
                        src={URL.createObjectURL(recording)}
                        type="audio/wav"
                      />
                      Your browser does not support the audio element.
                    </audio>
                    <div className="d-flex justify-content-center align-items-center gap-3">
                      {transcribedStatus[index] ? (
                        <>
                          <Tooltip
                            title="Transcribe again"
                            arrow
                            placement="top"
                          >
                            <IconButton
                              onClick={() => sendAudioToApi(recording, index)}
                            >
                              <Refresh />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Button
                          variant="text"
                          sx={{
                            borderRadius: "20px",
                            bgcolor: "#FFFFFF",
                            color: "#414141",
                            fontSize: "14px",
                            textTransform: "capitalize",
                            "&:hover": {
                              background: "#FFFFFF",
                            },
                          }}
                          className="d-flex border justify-content-center align-items-center gap-2 rounded-3 px-3"
                          onClick={() => sendAudioToApi(recording, index)} // Transcribe initially
                        >
                          Transcribe
                        </Button>
                      )}
                      <Tooltip title="Delete" arrow placement="top">
                        <IconButton onClick={() => removeRecording(index)}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <Backdrop
            sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </div>
  );
};

export default AudioVisualizer;
