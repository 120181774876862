import { Close, PlayCircle, PlayCircleOutline } from "@mui/icons-material";
import {
  Breadcrumbs,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function HelpCenterVideos() {
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const handleClickOpen = (url, name) => {
    setUrl(url);
    setTitle(name);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setUrl("");
    setTitle("");
  };
  const urls = [
    {
      url: "https://www.youtube.com/embed/1gXMT3XbLeg?si=uoHGMJgBjpPlUfFy&loop=1&playlist=1gXMT3XbLeg",
      title: `HOW CAN ${process.env.REACT_APP_CLIENT_DISPLAY_NAME.toUpperCase()} CLINICAL COPILOT PROVIDE DECISION ASSISTANCE`,
    },
    {
      url: "https://www.youtube.com/embed/vayJjCZ4zNI?si=E5DJNw2uudOTFp4r&loop=1&playlist=vayJjCZ4zNI",
      title: "HOW TO GENERATE A CLINICAL NOTE",
    },
    // {
    //   url: "https://www.youtube.com/embed/vYsZjtcjzvM?si=zYvYuQT7lGd5cfPO&loop=1&playlist=vYsZjtcjzvM",
    //   title: "HOW TO GENERATE A HEALTH INTAKE FORM",
    // },
    {
      url: "https://www.youtube.com/embed/8fLCJzKx-C8?si=bLANp54mbZYBttOB&loop=1&playlist=8fLCJzKx-C8",
      title: "HOW TO MAKE EASY RECOMMENDATIONS",
    },
  ];
  return (
    <div className="mt-3 ExpertHelpCenter--Dashboard">
      <div className="ExpertHelpCenter--Header d-flex justify-content-between align-items-center">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              onClick={() => {
                navigate("/clinical/help-center");
              }}
              role="button"
              color="text.primary"
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                textDecoration: "none",
              }}
              className=" d-flex text-secondary align-items-center justify-content-between gap-2 "
            >
              Help Center
            </Link>

            <Typography
              sx={{ fontWeight: "500", fontSize: "20px" }}
              color="text.primary"
            >
              Videos
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div className="d-none">
        <Typography
          sx={{ fontWeight: "500", fontSize: "24px" }}
          className="mt-3"
          color="text.primary"
        >
          Tutorials & Demos
        </Typography>
        <p style={{ fontSize: "1rem" }} className="mt-3 mb-5">
          Watch guides, product demos, and customer testimonials to learn how to
          effectively use our services
        </p>
      </div>

      {/* <div style={{height:"70vh"}} className="mt-5 d-flex  justify-content-center flex-column gap-3 align-items-center">
        <p className=" display-5 fw-bold">Oops, no videos to show at the moment</p>
       
      </div> */}
      <div className="row row-cols-1 row-cols-md-4 row-cols-lg-4 ">
        {urls.map((item, index) => (
          <div className="p-3 " key={index}>
            <div className="h-100 shadow  rounded-4">
              <div
                role="button"
                style={{ background: "white" }}
                className="help_center_videos position-relative rounded-4 "
                data-help_center-videos={item?.title}
                onClick={() => handleClickOpen(item?.url, item?.title)}
              >
                <img
                  style={{ aspectRatio: "16/9", objectFit: "cover" }}
                  src="/assets/img/rafiki1.png"
                  className=""
                />
                <div className="position-absolute  z-index-3 start-50 top-50 translate-middle rounded-circle bg-white ">
                  <PlayCircleOutline
                    sx={{ fontSize: 40, color: "var(--primary-color)" }}
                  />
                </div>
                <div
                  style={{
                    height: "100%",
                    borderBottomLeftRadius: "1rem",
                    borderBottomRightRadius: "1rem",
                    background:
                      "linear-gradient(to top, rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))",
                  }}
                  className=" position-absolute w-100   bottom-0 "
                ></div>
              </div>{" "}
              <p
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  fontSize: "14px",
                }}
                className="px-3 lh-sm mt-3 fw-semibold "
              >
                {item?.title}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Dialog
        className="Help-center-video-dialog"
        open={open}
        onClose={handleClose}
      >
        <DialogContent sx={{ paddingTop: 0 }}>
          <div className="d-flex  py-3 justify-content-between align-items-center">
            <span
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                fontSize: "16px",
              }}
              className="fw-semibold"
            >
              {title}
            </span>{" "}
            <IconButton sx={{ background: "#7f7f7f40" }} onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          <iframe
            src={url}
            title="YouTube video player"
            className="w-100 "
            style={{ aspectRatio: "16/9" }}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default HelpCenterVideos;
